import apiClient from '@/_shared/services/apiClient';
import { AnalyticsReport, ReportPage } from '@/_shared/types/analytics';

export interface ReportsApiResponse { reports: AnalyticsReport[] }

export interface PagesApiResponse { pages: ReportPage[] }

export interface EmbedTokenResponse {
  token: string;
  tokenId: string;
  expiration: string;
}

export const getAllReports = async () => {
  try {
    const response = await apiClient
      .get<ReportsApiResponse>('/api/v2/me/analytics/all_reports');
    return response.reports;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getCurrentPersonReports = async () => {
  try {
    const response = await apiClient
      .get<ReportsApiResponse>('/api/v2/me/analytics/reports');
    return response.reports;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPages = async (workspaceId: string, reportId: string) => {
  try {
    const response = await apiClient
      .get<PagesApiResponse>(`/api/v2/me/analytics/${workspaceId}/reports/${reportId}/pages`);
    return response.pages;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getEmbedToken = async (workspaceId: string, reportId: string) => {
  try {
    return apiClient
      .get<EmbedTokenResponse>(`/api/v2/me/analytics/${workspaceId}/reports/${reportId}/token`);
  } catch (error) {
    console.error(error);
    return {};
  }
};
